import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PublicRoute({ children }: any) {
  const isAuthenticated = useSelector((state: any) => state.auth.user);
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
}
