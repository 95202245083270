import axios from "axios";
import CONSTANT from "./constant";
import { config } from "../config";
import { setUser } from "../store/authSlice";

axios.defaults.baseURL = config.apiServerUrl;
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.status === 406)
    ) {
      if (!window.location.href.includes("/logout")) {
        window.location.href = "/logout";
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export function initUserFromStorage() {
  return (dispatch: any) => {
    const user = getUserStorage();
    if (user) {
      if (user.token) {
        setAxiosHeaders(user.token);
      }
      dispatch(setUser(user));
    }
  };
}

export const setAxiosHeaders = (token: string) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const getUserStorage = () => {
  if (localStorage.getItem(CONSTANT.STORAGE_KEY.DRIVER_USER)) {
    const user = JSON.parse(
      localStorage.getItem(CONSTANT.STORAGE_KEY.DRIVER_USER) || "{}"
    );
    return user.user;
  }

  return undefined;
};

export const setUserStorage = (newUser: any) => {
  if (newUser && newUser.token) {
    const userStorage = {
      user: newUser,
    };

    localStorage.setItem(
      CONSTANT.STORAGE_KEY.DRIVER_USER,
      JSON.stringify(userStorage)
    );
  } else {
    localStorage.removeItem(CONSTANT.STORAGE_KEY.DRIVER_USER);
  }
};
