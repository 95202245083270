const CONSTANT = {
  STORAGE_KEY: {
    DRIVER_USER: 'driver-user',
    SAVED_URL: 'saved-url',
    MESSAGES: 'messages',
  },
  DATE_FORMAT: {
    LONG: 'DD/MM/YYYY HH:mm:ss',
    SHORT: 'DD/MM/YYYY',
    TIMEZONE: 'Asia/Ho_Chi_Minh',
  },
  SOCKET_EVENT: {
    MESSAGE_COUNT: 'message-count',
    NEW_MESSAGE: 'new-message',
    NEW_INSTANT: 'new-instant',
    NEW_OWNER_ASSIGNMENT: 'new-owner-assignment',
    NEW_MATCHING_OWNER: 'new-matching-owner',
    NEW_MATCHING_ORDER: 'new-matching-order',
    UPDATE_SUGGESTION: 'update-suggestion',
    UPDATE_MESSAGE: 'update-message',
  },
};

export default CONSTANT;
