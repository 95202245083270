import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    pageTitle: "",
    newChatMessage: "",
    unreadMessageCount: 0,
    updateChatMessage: "",
  },
  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
      document.title = action.payload;
    },
    setNewChatMessage: (state, action) => {
      state.newChatMessage = action.payload;
    },
    setUnreadMessageCount: (state, action) => {
      state.unreadMessageCount = action.payload;
    },
    setUpdateChatMessage: (state, action) => {
      state.updateChatMessage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPageTitle,
  setNewChatMessage,
  setUnreadMessageCount,
  setUpdateChatMessage,
} = appSlice.actions;

export default appSlice.reducer;
