import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import { Provider } from 'react-redux';

import App from './App';
import store from './store';
import reportWebVitals from './reportWebVitals';
import { initUserFromStorage } from './utils/utils';
import { initSocket } from './store/socket';

import './index.scss';
import 'react-tooltip/dist/react-tooltip.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
// import "react-date-range/dist/styles.css"; // main style file
// import "react-date-range/dist/theme/default.css"; // theme css file
// import "react-flow-renderer/dist/style.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "react-image-crop/dist/ReactCrop.css";
import 'react-awesome-lightbox/build/style.css';

store.dispatch(initUserFromStorage());
store.dispatch(initSocket());

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <BrowserRouter basename="/">
      <PrimeReactProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </PrimeReactProvider>
    </BrowserRouter>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
