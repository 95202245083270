import { Outlet } from "react-router-dom";

import Header from "../../layout/Header/Header";

import "./WithNavbar.scss";

export default function WithNavbar() {
  return (
    <div className="with-navbar min-w-screen">
      <div className="fixed inset-0 bg-[#F6F5F5] -z-10" />
      <Header />
      {/* <Sidebar /> */}
      <div className="vic-container mt-header h-fit flex flex-col space-y-4">
        <Outlet />
      </div>
    </div>
  );
}
