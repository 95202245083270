import io from "socket.io-client";
import { config } from "../config";
import CONSTANT from "../utils/constant";
import { getUserStorage } from "../utils/utils";
import {
  setNewChatMessage,
  setUnreadMessageCount,
  setUpdateChatMessage,
} from "./appSlice";

let socketConnection: any;

export function initSocket() {
  return (dispatch: any) => {
    const user = getUserStorage();
    if (user) {
      if (user.token) {
        registerSocketEvents(dispatch, getSocketConnection());
      }
    }
  };
}

function createSocketConnection() {
  const user = getUserStorage();
  socketConnection = io(
    `${config.socketServerUrl}?token=${user.token}&app=web`,
    {
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10,
      transports: ["websocket"],
      upgrade: false,
    }
  );

  return socketConnection;
}

function getSocketConnection() {
  return socketConnection || createSocketConnection();
}

function registerSocketEvents(dispatch: any, socket: any) {
  // const user = getUserStorage();
  socket.removeAllListeners();

  socket.on(CONSTANT.SOCKET_EVENT.MESSAGE_COUNT, (data: number) => {
    if (data > 0) {
      // const audioNotification = new Audio(AudioMessage);
      // audioNotification.play().then((r) => {});
    }
    dispatch(setUnreadMessageCount(data));
  });

  socket.on(CONSTANT.SOCKET_EVENT.NEW_MESSAGE, (data: any) => {
    if (data) {
      const newMsg = JSON.parse(data);
      if (
        newMsg.type !== "system-adv" &&
        newMsg.type !== "system" &&
        newMsg.type !== "system-time"
        //  ||
        // Number(newMsg.message?.user_id) !== user.id
      ) {
        // const audioNotification = new Audio(AudioMessage);
        // audioNotification.play().then((r) => {});
      }
    }
    dispatch(setNewChatMessage(data));
  });

  socket.on(CONSTANT.SOCKET_EVENT.UPDATE_MESSAGE, (data: any) => {
    dispatch(setUpdateChatMessage(data));
  });

  socket.on("error", () => {
    window.location.reload();
  });

  socket.on("disconnect", () => {
    if (process.env.NODE_ENV !== "development") {
      // window.location.reload();
    }
  });
}
