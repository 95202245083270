import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import PrivateRoute from './components/routerHelper/PrivateRoute';
import PublicRoute from './components/routerHelper/PublicRoute';
import WithNavbar from './components/WithNavbar/WithNavbar';

import NotFound from './pages/NotFound/NotFound.lazy';
import Login from './pages/Login/Login.lazy';
import Chat from './pages/Chat/Chat.lazy';
import Home from './pages/Home/Home.lazy';
import Logout from './pages/Logout/Logout.lazy';

import './App.scss';

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <WithNavbar />
            </PrivateRoute>
          }
        >
          <Route path="/" element={<Chat />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/new" element={<Home />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/not-found" element={<NotFound />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
}

export default App;
